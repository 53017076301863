

.panel {
  border: none;
  box-shadow: none;
}
.page__title {
  font-size: 24px;
  color: #44b770;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.31);
  margin-top: 0;
  margin-bottom: 19px;
  font-weight: 400;
}
.panel-heading {
  padding: 10px 0 10px 25px;
  margin-bottom: 18px;

  background: #f7f5f5!important;
  border: 1px solid gainsboro;
  border-radius: 0;
}
.panel-title {
  &.green {
    color: #44b770;
  }
  color: #7d8c8f;
  font-size: 18px;
  line-height: 1.42857143;
}
.panel-body {
  padding: 0;
}
html * {
  text-shadow: 0 0 0 transparent!important;
  outline: none!important;
}
.page__navigation {
  margin-bottom: 28px;
  .btn {
    margin-right: 18px;
  }
}
@media screen and (max-width: 639px) {
  .page__navigation {
    display: block;
    margin-bottom: 28px;
    text-align: center;
    .btn {
      margin: 0 9px;
      &:first-child {
        vertical-align: top;
        margin-bottom: 15px;
      }
    }
  }
}
img.qimages {
  margin-left: 5px;
}
hr {
  border: none;
  color: #dcdcdc;
  background-color: #dcdcdc;
  height: 1px;
}
.modal-content {
  #supportform-subject-styler {
    display: none!important;
  }
}