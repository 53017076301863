.pagination {
  margin-top: 0;
  width: 100%;
  li {
    &.prev a{
      border: none;
      font-size: 24px;
      border-radius: 50%;
    }
    &.next a{
      border: none;
      font-size: 24px;
      border-radius: 50%;
    }
    &.active {
      a {
        border: 1px solid #7d8c8f;
        background: #7d8c8f;
        color: #fff;
      }
    }
    a {
      float: none;
      padding: 0;
      border-radius: 333px;
      color: #7d8c8f;
      margin-left: 4px;
      margin-right: 4px;
      display: inline-block;
      text-align: center;
      line-height: 39px;
      width: 38px;
      height: 38px;
      border: 1px solid #7d8c8f;
      text-decoration: none!important;
      &:hover {
        opacity: 0.8;
        color: #fff;
        background: #7d8c8f;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .pagination {
    text-align: center;
  }
}
@media screen and (max-width: 1139px) {
  .pagination {
    li:nth-child(1), li:nth-child(2), li:nth-child(3),li:nth-child(4),li:nth-child(5),li:nth-child(6),li:nth-child(7),li:nth-child(8),li:nth-child(9),li:nth-child(10),li:last-child {
      display: inline-block;
    }
    li {
      display: none;
    }
  }
}
@media screen and (max-width: 639px) {
  .pagination {
    li:nth-child(9),li:nth-child(10) {
      display: none;
    }
  }
}
@media screen and (max-width: 479px) {
  .pagination {
    li:nth-child(6),li:nth-child(7),li:nth-child(8) {
       display: none;
    }
    li a{
      margin-right: 3px;
    }
  }
}

.limit {
  float: right;
  display: inline-block;
  margin-bottom: 40px;
}
.limit__title {
  margin-top: 10px;
  float: left;
}
.limit__list {
  float: left;
  @include cf();
}
.limit__item {
  float: left;
  list-style-type: none;
  &._active {
    .limit__link {
      background: #7d8c8f;
      color: #fff;
    }
  }
}
.limit__link {
  border-radius: 333px;
  color: #7d8c8f;
  margin-left: 4px;
  margin-right: 4px;
  display: inline-block;
  text-align: center;
  line-height: 39px;
  width: 38px;
  height: 38px;
  border: 1px solid #7d8c8f;
  text-decoration: none!important;
  &:hover {
    opacity: 0.8;
    color: #fff;
    background: #7d8c8f;
  }
}
@media screen and (max-width: 1199px) {
  .limit {
    width: 100%;
    float: none;
    text-align: center;
  }
  .limit__title,
  .limit__list {
    display: inline-block;
    float: none;
    padding: 0;
  }
  .limit__title {
    display: block;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
}