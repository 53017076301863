.container._pay {
  margin-bottom: 25px;
  .page__title {
    span {
      color: #7D8C8F;
      font-weight: 500;
    }
  }
  h2 {
    font-size: 18px;
    font-weight: 500;
    color: #74b673;
  }
  .checkbox-inline {
    padding: 0;
  }
  .saveform2 {
    width: 180px;
  }
}
#sum-block {

}
#paymsum {
  width: 135px;
  padding-left: 24px;
  padding-right: 24px;
  height: 39px;
  line-height: 39px;
  color: #7D8C8F;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 39px;
  background-color: #F7F5F5;
  border: 1px solid #DCDCDC;
  border-radius: 19px;
}
.field-paymentform2-confirm {
  a {
    color: #44B770;
  }
  label {
    padding: 0;
  }
}
.payments {
  a {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px){
  .page__title {
    font-size: 21px
  }
}