.finance__title {
  text-shadow: 0px 1px 2px rgba(255, 255, 255, 0.31);
  color: #44B770;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 19px;
  font-weight: normal;
}
.my-finance {
  font-size: 14px;
  margin-bottom: 40px;
  thead {
    border: solid #DCDCDC;
    border-width: 1px 1px 0 1px;
    tr {
      th {
        border: none;
        line-height: 16px!important;
        &:first-child {
          padding-left: 50px !important;
        }
        a {
          vertical-align: text-bottom;
          margin-left: 10px;
        }
      }
    }
  }
  tfoot {
    border: solid #DCDCDC;
    border-width: 0 1px 1px 1px;
  }
  thead, tfoot {
    background-color: #F7F5F5;
    tr {
      td,th {
        font-size: 12px;
        line-height: 1.42857143;
        font-weight: normal;
        color: #727272;
      }
    }
  }
  tbody>tr:hover{
    background-color: #fcfae3;
  }
  tbody>tr>td {
    vertical-align: middle;
    padding: 17px 15px;
    .red {
      color: #FF4747;
    }
    &:first-child {
      padding-left: 50px !important;
    }
  }
  tfoot>tr>td {
    padding: 4px 15px;
    vertical-align: baseline;
    height: 30px;
    p {
      display: inline-block;
      line-height: 22px;
      vertical-align: baseline;
    }
  }
  .footable-detail-row {
    td:first-child {
      padding-left: 0!important;
    }
    th {
      a img {
        display: none;
      }
    }
  }
}
