.page__body._ad {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 15px;
}
.panel-body._ad {
  padding-left: 25px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 15px;
  font-size: 16px;
  color: #7d8c8f;
  .title {
    font-size: 18px;
    color: #ff4747;
    margin: 10px 0;
  }
}
#copy_btn {
  background: #74b673;
  border-radius: 15.5px;
  height: 34px;
  line-height: 34px;
  font-family: 'geometria', sans-serif;
  font-size: 14px;
  color: #fdfefe;
  border: none;
  margin-left: 20px;
  padding: 8px 20px;
  font-style: normal;
}
.ad__refer-link-field {
  max-width: 377px;
  width: 100%;
  border: 1px solid #727272;
  border-radius: 30px;
  height: 25px;
  padding: 0px 20px;
  margin-top: 10px;
}
.ad__refer-link-text {
  font-size: 12px;
  color: #71b270;

}
.ad__refer-link {
  margin-bottom: 15px;
}
.banner-links {
  text-align: center;
  img {
    margin-bottom: 15px;
  }
  a {
    color: #74b673;
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {

  .banner-links {
    img {
      margin-top: 25px!important;
    }
  }
}
@media screen and (max-width: 639px) {
  .ad__refer-link-text {
    margin-bottom: 10px;
  }
  .ad__refer-link {
    text-align: center;
    margin-bottom: 15px;
    .ad__refer-link-field {
      display: block;
      margin: 0 auto 15px;
    }
    #copy_btn {
      margin: 0;
    }
  }
}