.my-projects {
  font-size: 14px;
  margin-bottom: 40px;
  thead {
    border: solid #DCDCDC;
    border-width: 1px 1px 0 1px;
    tr {
      th {
        border: none;
      }
    }
  }
  tfoot {
    border: solid #DCDCDC;
    border-width: 0 1px 1px 1px;
    strong {
      font-weight: 500;
    }
  }
  thead, tfoot {
    background-color: #F7F5F5;
    tr {
      th,td {
        font-size: 12px;
        padding: 8px 15px!important;
        line-height: 1.42857143;
        font-weight: normal;
        color: #727272;
        &:first-child {
          padding: 0!important;
        }
        .checkbox {
          display: inline-block;
          margin: 0 0 8px;
          padding: 0 15px;
          label {
            padding: 5px;
          }
         }
        img {
          display: inline-block;
          margin-top: -2px;
        }
      }
    }
  }
  tbody>tr:hover{
    background-color: #fcfae3;
  }
  tbody>tr>td {
    vertical-align: middle;
  }
  tfoot>tr>td {
    padding: 4px 15px;
    vertical-align: baseline;
    .glyphicon-play,
    .glyphicon-stop {
      vertical-align: text-bottom;
    }
    p {
      display: inline-block;
      line-height: 22px;
      vertical-align: baseline;
    }
  }
  .target-name {
    font-weight: 700;
  }
  .target-link {
    font-size: 12px;
  }
  .budget-link {
    color: #FF4747;
  }
  p {
    margin: 0;
  }
}
.glyphicon-play {
  font-size: 22px;
  color: #44B770;
  margin: 0;
  padding: 0;
  &:hover {
    color: #44B770;
  }
}
.glyphicon-stop {
  font-size: 22px;
  color: #FF4747;
  margin: 0;
  padding: 0;
}
.settings-btn {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url('../images/icon-setting.png') center no-repeat;
  margin-right: 20px;
}
.refresh-btn {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url('../images/icon-refresh.png') center no-repeat;
}
.pay-btn {
  color: #FF4747;
  font-size: 12px;
  font-weight: 400;
  min-width: 89px;
  text-align: center;
  padding: 0;
  line-height: 25px;
  display: inline-block;
  text-decoration: none;
  height: 25px;
  border: 1px solid #FF4747;
  border-radius: 12px;
  &:hover {
    background-color: #FF4747;
    color: #fff;
  }
}
.footable-row-detail {
  td {
    .glyphicon {
      margin-left: 20px;
    }
  }
}