.post__header {
}
.post__link-back {
  text-decoration: underline;
  color: #74b673;
  font-size: 16px;
}
.post__header-view {
  position: relative;
  margin-bottom: 10px;
}
.post__header-image {
  display: block;
  width: 100%;
}
.post__header-image-caption {
  position: relative;
}
.post__header-caption-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 24px;
  text-align: center;
  text-shadow: #000 2px 2px 2px;
}
.post__main {
  color: #737d7f;
  text-align: justify;
  font-size: 16px;
  margin-top: 8px;
  h2 {
    font-size: 18px;
    color: #74b673;
  }
  li {
    padding: 0 0 10px 5px;
    margin-left: 40px;
    list-style-type: disc;
    color: #44B770;
    span {
      color: #7d8c8f;
    }
  }
}
@media screen and (max-width: 767px)  {
  .page__body {
    padding: 0;
  }
  .post__header-image-caption {
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
  }
  .post__header-caption-text {
    position: relative;
    transform: none;
    color: #737d7f;
    text-shadow: none;
    padding: 10px 0;
    font-size: 20px;
  }
  .post__main {
    h2 {
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
      color: #74b673;
    }
    p {
      font-size: 14px;
    }
    img {
      width: 100%;
    }
    li {
      margin-left: 20px;
    }
  }
}