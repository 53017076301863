.container._budget {
  .page__title {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 400;
  }
  .panel {
    padding-bottom: 15px;
    border-bottom: 1px solid #dcdcdc;
  }
}
#priceByOne {
  font-family: Geometria;
  color: #FF4747;
  font-size: 24px !important;
  font-weight: 500 !important;
  text-align: center;
  display: block;
  margin-bottom: 12px;
}
.field__wrap._budget {
  margin-bottom: 25px;
  .field__property {
    .qimages {
      margin-right: 10px;
      margin-left: 0;
    }
    label {
      input {
        margin-right: 5px;
      }
    }
  }
  .field__value {
    input {
      width: 85px !important;
      float: left;
      height: 25px;
      border: 1px solid #B6B6B6 !important;
      border-radius: 12px !important;
      line-height: 25px;
      text-align: center;
      color: #44B770;
      font-size: 14px;
      margin-top: 0px !important;
      font-weight: 500;
      margin-right: 8px;
    }
    @media screen and (max-width: 639px) {
      padding: 0 0;
    }
  }
}
.green {
  color: #44B770;
}
.important {
  color: #ff0000;
}

.priceOne {
  max-width: 296px;
  padding: 24px 15px;
  border: 1px solid #B6B6B6;
  border-radius: 15px;
  float: right;
  margin-left: -15px;
  text-align: center;
}
.budget__scale {
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 16px;
    position: absolute;
    bottom: 38px;
    left: 0;
    background: url("../images/scale_2.png") 15px bottom repeat-x;
    z-index: 5;
  }
}
.scale-nums > span {
  margin-right: 18px;
}
#paket20 {
  margin-left: 2px;
}

#paket25 {
  margin-left: -1px;
}

#paket30, #paket45, #paket50, #paket60, #paket80 {
  margin-left: -2px;
}

#paket65, #paket95 {
  margin-left: -4px;
}

#paket100 {
  margin-left: -4px;
  margin-right: 14px;
}

#paket110 {
  margin-left: -4px;
  margin-right: 14px;
}

#paket115 {
  margin-right: 10px;
  margin-left: 5px;
}

#paket120 {
  margin-right: 13px;
  margin-left: 4px;
}

#paket130 {
  margin-right: 13px;
  margin-left: -3px;
}

#paket135 {
  margin-right: 10px;
  margin-left: 0;
}

#paket145 {
  margin-right: -5px;
  margin-left: -6px;
}

#paket150 {
  margin-left: 15px;
  margin-right: 0;
}
.budget__scale-left {
  float: left;
  height: 60px;
  width: 48.5%;
  background: #72c8ff;
  font-size: 17px;
  color: #fff;
  text-align: center;
  line-height: 50px;
}
.budget__scale-right {
  float: left;
  height: 60px;
  width: 51.5%;
  background: #44B770;
  font-size: 17px;
  color: #fff;
  text-align: center;
  line-height: 50px;
}

.budget__month {
  @include cf();
}
.budget__m-result {
  float: left;
  text-align: center;
}
.budget__m-result-text {
  margin-bottom: 10px;
  color: #44B770;
}
.budget__m-result-sum {
  color: #FF4747;
  font-size: 24px;
}
.budget__m-value {
  float: left;
  display: none;
  text-align: center;
}
.budget__m-value-text {
  margin-bottom: 10px;
  color: #44B770;
}
.budget__m-value-sum {
  color: #72c8ff;
  font-size: 24px;
}
#polz_paket {
  position: relative;
  height: 12px;
  background-color: #fcf8e3;
  z-index: 100;
}
.ui-widget-content {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 3px;
  color: #333;
}
.ui-slider-handle {
  background-color: #e8e8e8 !important;
  border: 1px solid #b6b6b6 !important;
  position: absolute !important;
  top: -9px !important;
  margin-left: -13px !important;
  z-index: 200 !important;
  outline: none !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 1000002px !important;
}
.label-scale {
 margin-bottom: 15px;
}
.budget__navigation-btns {
  width: 100%;
  padding:0 15px;
  margin-bottom: 25px;
}
.budget__navigation-btn {
  width: 45%;
  max-width: 180px;
  margin-right: 4%;
  &:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1184px) {
  .budget__scale {
    &:after {
      bottom: 18px;
      background: url("../images/scale.png") left bottom repeat-x;
    }
    width: calc(100% - 305px);
  }
  .budget__month {
    width: 290px;
    padding: 0;
    border: 1px solid #B6B6B6;
    border-radius: 15px;
  }
  .budget__m-value {
    display: block;
    width: 50%;
  }
  .budget__m-result {
    width: 50%;
  }
  .budget__m-value-text {
    display: table-cell;
    vertical-align: middle;
    height: 80px;
    border-bottom: 1px solid #B6B6B6;
    border-right: 1px solid #B6B6B6;
  }
  .budget__m-result-text {
    display: table-cell;
    vertical-align: middle;
    height: 80px;
    border-bottom: 1px solid #B6B6B6;
  }
  .budget__m-value-sum {
    margin-bottom: 0;
    padding: 5px 0;
    vertical-align: middle;
    border-right: 1px solid #B6B6B6;
  }
  .budget__m-result-sum {
    margin-bottom: 0;
    padding: 5px 0;
    vertical-align: middle;
  }
  .scale-nums {
    display: none;
  }
  .discrets {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .budget__scale {
    width: 100%;
  }
  .priceOne {
    margin: 25px 0;
    float: none;
  }
  .budget__month {
    margin: 25px 0 0 15px;
  }
}