.footer__wrap {
  background-color: #41b36c;
}
.footer {
  padding: 20px 0;
  color: #fff;
  text-align: center;
}
.footer__logo {
  float: left;

}
.footer__copy-right {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  color: #fff;
  line-height: 32px;
  margin-bottom: 0;
}
.footer__support {
  float: right;
  color: #fff;
  text-decoration: underline;
  line-height: 32px;
  font-size: 15px;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}
@media screen and (max-width: 559px) {
  .footer {
    position: relative;
    padding-bottom: 30px;
  }
  .footer__copy-right {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
