.faq {
  .page__title {
    margin: 0 0 25px;
  }
  .panel-heading {
    position: relative;
    .glyphicon {
      display: none;
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
      position: absolute;
      top: 13px;
      right: 20px;
      color: #41b36c;
    }
    &.collapsed {
      .glyphicon:before {
        content: '\e250';
      }
    }
  }
}
.collapsed
.faq__question-list {
  @include cf;
  padding: 0;
}
.faq__question-item {
  list-style-type: none;
}

.faq__question-list {

}
.faq__question-item {
  margin-bottom: 8px;
  padding: 0 5px;
}
.faq__question-link {
  font-weight: 500;
  color: #7d8c8f;
  font-size: 14px;
  &:hover {
    color: #44b770;
  }
}
.faq__topics-list {
  padding: 0;
}
.faq__topics-item {
  list-style-type: none;
}
.faq__topics-link {
  display: block;
  padding-left: 24px;
  padding-right: 10px;
  color: #7d8c8f;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gainsboro;
  &:hover {
    color: #44b770;
  }
}
.faq__answer-list {
  padding: 0;
}
.faq__answer-item {
  list-style-type: none;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.answer__header {
  @include cf();
  margin-bottom: 20px;
}
.answer__title {
  float: left;
  color: #ff4747;
  font-size: 18px;
}
.answer__rating-block {
  margin-right: -20px;
  float: right;
  font-size: 13px;
  color: #7d8c8f;
  line-height: 25px;
}
.answer__rating-text {
  margin-right: 5px;
}
.answer__rating-list {
  i {
    color: #41b36c;
  }
}
.answer__main {
  overflow: hidden;
  margin-bottom: 10px;
}
.answer__footer {
  @include cf();
}
.answer__interview {
  display: inline-block;
  @include cf();
  float: right;
  line-height: 25px;
}
.answer__interview-text {
  float: left;
}
.answer__interview-value {
  float: left;
  margin-left: 10px;
}

@media screen and (max-width: 991px) {
  .faq {
    .panel-heading {
      .glyphicon {
        display: block;
      }
    }
  }
  .answer__main {
    img {
      width: 100%;
    }
  }
  .faq__question-item {
    margin-bottom: 12px;
  }
  .faq__question-link {
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .faq {
    .panel-heading {
      position: relative;
      padding-left: 10px;
      .glyphicon {
        right: 5px;
      }
    }
  }
}