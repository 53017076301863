.page__body._partners {

}
.partners__info {
  float: left;
}
.partners__navigation {

}
.partners__offers {

}
.partners__offer-item {
  letter-spacing: -0.3px
}
.partners__offer-text {
  font-size: 16px;
  color: #74b673;
}
.partners__offer-image {
  margin-left: 5px;
  color: #4c4c4c;
}

.partners__balance-info  {
  float: right;
  min-width: 260px;
}
.partners__balance-item {

}
.partners__balance-property {

}
.partners__balance-value {
  color: #74b673;
}
.partners__links {
  padding-top: 42px;
}
.partners__link {
  color: #4c4c4c;
  text-decoration: underline;
  font-weight: 500;
}
.partners__refer-balance {
  float: right;
  padding: 25px 0 0 0;
  margin-bottom: 25px;
}
.partners__refer-item {
  margin-bottom: 10px;
}
.partners__refer-text {
  float: left;
  img {

  }
}
.partners__refer-value {
  width: 140px;
  height: 25px;
  border: 1px solid #7d8c8f;
  text-align: center;
  font-family: 'geometria', sans-serif;
  font-size: 12px;
  margin-top: -3px;
  margin-left: 20px;
  border-radius: 30px !important;
  padding: 5px 15px;
  float: right;
}

.partners__table {
  th {
    padding: 8px;
    line-height: 1.42857143;

    background-color: #F7F5F5 !important;
    border-top: 1px solid #DCDCDC !important;
    border-bottom: 1px solid #DCDCDC !important;
    color: #727272;
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 12px;
    font-weight: normal;

    vertical-align: bottom;
  }
  td {
    background: #fff;
    padding: 20px 15px !important;
    .footable-details th {
      padding: 11px 10px;
    }
  }
  a:hover {
    color: #a3adaa;
  }
}
@media screen and (max-width: 1139px) {
  .partners__table {
    tfoot tr td {
      display: none;
    }
    tfoot tr td.hide {
      display: table-cell!important;
      text-align: right;
    }
  }
}
@media screen and (max-width: 959px) {
  .page__container {
    padding: 0 15px;
  }
  .partners__info {
    margin-bottom: 25px;
  }
  .partners__balance-info  {
    width: 100%;
  }
  .partners__balance-item-list {
    width: 270px;
    float: left;
  }
  .partners__balance-item {
    width: 270px;
  }
  .partners__links {
    width: 150px;
    float: right;
    padding-top: 0;
  }
  .partners__link {
    float: right!important;
    padding: 5px 0;
  }
}
@media screen and (max-width: 639px) {
  .partners__links {
    width: 100%;
    float: left;
    padding-top: 0;
  }
  .partners__link {
    width: 150px;
    float: left!important;
    padding: 5px 0;
  }
  .partners__refer-balance {
    float: left;
  }
  .partners__refer-text {
    float: none;
  }
  .partners__refer-value {
    float: left;
    margin-left: 0;
  }
}