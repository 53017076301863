.page__body._blog {

}
.blog__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog__item-wrap {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 25px;
  margin-bottom: 40px;
}
.blog__item {

}
.blog__item-header {

}
.blog__item-date {
  font-size: 16px;
  color: rgb(115, 125, 127);
  margin-bottom: 5px;
}
.blog__item-main {

}
.blog__item-view {
  position: relative;
  margin-bottom: 10px;
}
.blog__item-image {
  display: block;
  width: 100%;
}
.blog__item-image-caption {
  position: relative;
}
.blog__item-caption-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 24px;
  text-align: center;
  text-shadow: #000 2px 2px 2px;
}
.blog__item-info {

}
.blog__item-description {
  color: #737d7f;
  text-align: justify;
  font-size: 16px;
  margin-top: 8px;
}
.blog__item-footer {
  @include cf();
}
.blog__item-link {
  float: right;
  text-decoration: underline;
  color: #74b673;
  font-size: 16px;
}
@media screen and (max-width: 767px)  {
  .page__body {
    padding: 0;
  }
  .blog__item-image-caption {
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
  }
  .blog__item-caption-text {
    position: relative;
    transform: none;
    color: #737d7f;
    text-shadow: none;
    padding: 10px 0;
    font-size: 20px;
  }
}