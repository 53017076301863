._btn {
  box-sizing: border-box;
  display: inline-block;
  padding: 0 47px;
  line-height: 40px;
  border: 1px solid #44b770;
  border-radius: 20px;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.31);
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.59);
  font-size: 14px;
  font-weight: 500;
  color: #4c4c4c;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    opacity: 0.8;
    color: #44b770;
  }

  &._active {
    background-color: #44b770;
    border: 1px solid #44b770;
    color: #fff;
  }
  &._disabled {
    border: 1px solid #d3d6d9;
    background-color: #E8E8E8;

  }
  &.i1 {
    padding: 0 47px 0 57px;
    background-image: url('../images/i1.png');
    background-position: 20px center;
    background-repeat: no-repeat;
  }
  &.i2 {
    padding: 0 47px 0 57px;
    background-image: url('../images/i2.png');
    background-position: 20px center;
    background-repeat: no-repeat;
  }
}

a.asc:after {
  content: /*"\e113"*/ "\e151";
}
a.desc:after {
  content: /*"\e114"*/ "\e152";
}
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}