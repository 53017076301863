#support-modal {

}

.modal-header {
  .modal-title {
    font-weight: 700;
  }
  button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    font-family: Geometria;
    font-weight: 700;
    font-size: 21px;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
  }
}

.modal-body {
  label {
    font-weight: 700;
  }
}

.modal-footer {
  .btn {
    &.btn-default {
      background: #FF4747 !important;
      border: 1px solid #FF4747 !important;
      border-radius: 12px !important;
      color: #fff;
      height: 34px;
      line-height: 20px;
    }
    &.btn-primary {
      background: #44B770 !important;
      border: 1px solid #44B770 !important;
      border-radius: 12px !important;
      min-width: 0;
      line-height: 20px;
    }
    &.send-request {

    }
  }
}

#supportform-subject {
  display: none;
}

#supportform-body {
  resize: vertical;
  height: auto;
}
#supportform-body, #list-request {
  border-radius: 30px;
}

#crossroad .modal-body .btn {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 20px;
}
#regInModal.geo {
 display: none;
}