.dashboard__nav {
  ._btn {
    margin-right: 18px;
  }
  margin-bottom: 28px;
}
.dashboard__action {
  margin-bottom: 37px;
}
.dashboard__add-project-btn {
  padding: 0 33px;
  background-color: #44b770;
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(55, 104, 4, 0.35);

  color: #fff;
  line-height: 33px;
  &:hover {
    color: #fff;
  }
}
.dashboard__filter {
  margin-top: 10px;
}
.dashboard__filter-text {
  margin-right: 40px;
}
.dashboard__filter-project-btn {
  padding: 0 33px;
  font-size: 12px;
  line-height: 23px;
  border-radius: 12px;
  border: 1px solid #dbd9d9;
  box-shadow: none;
  text-shadow: none;
  margin-right: 19px;
}

.dashboard__table {
  .my-projects {
    td {
      .checkbox {
        display: inline-block;
        margin-right: 5px;
        label {
          padding-left: 0;
        }
      }
      .fooicon {
        top: -3px;
      }
    }
  }
}
.my-projects__footer-mobile {
  display: none;
}
@media screen and (max-width: 959px) {
  .dashboard__table {
    .my-projects {
      td .checkbox,
      th .checkbox {
        display: none;
      }
      tr th {
        padding: 17px 15px;
      }
      .footable-detail-row td {
        padding: 0 15px;
      }
      tfoot td {
        display: none;
      }
    }
  }
  .my-projects__footer-mobile {
    display: table-cell!important;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .dashboard__nav {
    text-align: center;
    margin-bottom: 0;
  }
  .dashboard__nav ._btn {
    margin: 0 10px 25px;
  }
  .dashboard__action {
    text-align: center;
    .dashboard__add-project-btn {
      margin-bottom: 25px;
    }
  }
  .dashboard__filter {
    margin: 0;
  }
  .dashboard__filter-text {
    display: block;
    margin: 0 0 25px;
  }
  .dashboard__filter-project-btn {
    margin: 0 15px 15px 15px;
  }
}