.simple-tree-view {
  font-size: 14px;
}

.simple-tree-view li {
  padding: 4px 10px;
  border-radius: 3px;
}

.simple-tree-view li {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

#searchEngineContainer .active {
  background-color: #44B770 !important;
  color: #FFF !important;
  border: 1px solid #44B770 !important;
  box-shadow: none;
}

.form-control.geo {
  display: none;
}


#linksList {
  td button, input {
    width: 100%;
    border-radius: 20px;
    height: 40px;
    text-align: left;
  }
  .btn-default.disabled {
    background: #eeeeee !important;
    color: rgb(85, 85, 85) !important;
    border: 1px solid #B6B6B6
  }
}
.table.table-hover {
  th {
    padding: 8px;
    border-top: 1px solid #DCDCDC !important;
    border-bottom: 1px solid #DCDCDC !important;
    color: #727272;
    font-family: Geometria;
    font-weight: 500;
    font-size: 14px;
  }
  td {
    padding: 20px 15px;
    .form-group {
      margin-bottom: 0;
    }
    .glyphicon-trash {
      color: #ff4545;
    }
  }
}

#addLinkBtn {
  padding-left: 8px;
}

.btn-default {
  border-radius: 20px;
  height: 40px;
  line-height: 26px;
}
#projectform-searchengine {
  border-radius: 3px;
  overflow: hidden;
  .btn-default {
    border-radius: 0;
    box-shadow: none;
    height: 34px;
    line-height: 20px;
    background: #fff;
    &:hover {
      background-color: #e6e6e6;
      border-color: #adadad;
    }
    &.active {

    }
  }

}

.btn {
  &.modal-request {
    margin-bottom: 15px;
  }
  &.modal-remove-request {
    margin-bottom: 15px;
  }
}
.actions {
  position: relative;
}
.auto-complete {
  position: absolute;
  left: 4%;
  width: 92%;
  top: 100%;
  z-index: 1;
  padding: 0 15px;
  margin-top: -21px;
}
.drop-down{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out 0s;
}
[data-th="Анкор"]:hover .drop-down {
  max-height: 300px;
  transition: max-height 0.2s ease-in-out 0s;
}
.auto-complete-container.unselectable {
  border-radius: 0 0 20px 20px;
  border: 1px solid #44B770!important;
  border-top: none!important;
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  padding: 0;
  & > li {
    list-style-type: none;
    border-bottom: 1px dotted #555555;
    padding: 6px 12px;
    line-height: 15px;
  }
}
@media screen and (max-width: 767px) {
  #tableReqst {
    thead {
      th {
        display: none;
        border: none!important;
        &:last-child {
          display: block;
          border: none;
          padding: 0;
        }
      }
      #addLinkBtn {
        &:before {
          content: attr(data-th);
        }
        width: 100%;
        display: block;
        background: rgba(99,189,98,1.85);
        padding: 10px;
        border-radius: 3px;
        text-align: left;
        color: #f1f1f1;
        text-decoration: none;
        font-weight: 700;
        text-shadow: 0 0 1px #000!important;
        margin-bottom: 25px;
      }
      .glyphicon-plus {
        float: right;
        text-shadow: 0 0 1px #000!important;
      }
    }
    tbody tr {
      display: block;
      border: 1px solid #eee;
      margin-bottom: 25px;
      border-radius: 3px;
      &:hover {
        background: transparent!important;
      }
    }
    tbody td {
      .dropdown button {
        width: 100%!important;
        border-radius: 3px!important;
      }
      & > input {
        width: 100%!important;
        border-radius: 3px;
      }
      display: block;
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
      &:first-child {
        padding: 15px 15px;
        text-align: right;
        font-weight: 700;
        background: #fcfae3;
        &:before {
          float: left;
        }
      }
      &:last-child {
        display: inline-block;
        padding-left: 0;
        padding-top: 35px;
        &:before {
          display: none;
        }
        a {
          &:before {
            content: attr(data-th);
            padding-right: 20px;
          }
          display: inline-block;
          border: solid #ffb3b3;
          border-width: 1px 1px 0 0;
          border-radius: 0 3px 0 0;
          padding: 5px 25px;
          color: #ff4545;
          &:hover {
            text-decoration: none;
            cursor: pointer;
            background: #ffb3b3;
            color: #fff;
            transition: background 0.35s ease-in-out,
            color 0.35s ease-in-out;
          }
        }
      }
      &:before {
        content: attr(data-th);
      }
      .link {
        float: none;
      }
    }
  }
  .auto-complete {
    margin-top: 0;
  }
  .auto-complete-container.unselectable {
    border-radius: 0 0 3px 3px;
  }
}
#autoFillRequests {
  display: none;
}
.field-toggleLinks {
  display: none;
}
.modal-request {
  margin-right: 15px;
}