.page__body._about {
  margin-bottom: 25px;
  p {
    font-size: 16px;
    line-height: 1.5;
  }
  ul {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
  }
  li {
    padding: 0 0 10px 5px;
    margin-left: 40px;
    list-style-type: disc;
    color: #44B770;
    span {
      color: #7d8c8f;
    }
  }
}
@media screen and (max-width: 639px) {
  .page__body._about {
    li {
      margin-left: 20px;
    }
  }
}