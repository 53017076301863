.chat_widget {
  max-width: 600px;
  margin: 0 auto;
}
.message {
  width: 85%;
  margin: 10px 0;
  border-radius: 8px;
  padding: 10px 10px 30px 10px;
  position: relative;
}
.message.my {
  background-color: #e4f6fc;
}
.message .date {
  color: #7e93af;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
#disputemessage-message {
  min-height: 250px;
  border: none;
  box-shadow: none;
  resize: none;
  outline: none;
  border-radius: 20px;
  &:focus {
  border: 1px #44B770 solid;
  box-shadow: none;
}
}
.chat-counter-container {
  position: relative;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  border-radius: 20px;
  border: 1px solid #B6B6B6;
  padding-bottom: 30px;
}
.length-counter {
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: darkgrey;
}
#post_approved {
  display: block;
  max-width: 600px;
  margin: 20px auto;
}
.btn-primary.dispute {
  border: 1px solid #44B770;
  border-radius: 12px;
  min-width: 0;
}
.work-area-dispute-view {
  h2 {
    font-size: 18px;
    color: #74b673;
  }
}
.table-bordered.detail-view {
  &:last-child {
    margin-bottom: 40px;
  }
  th{
    width: 25%;
    background: #F7F5F5;
  }
  td {
    padding: 20px 15px;
    &:hover {
      background: #fcfae3 !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .message {
    width: 100%;
  }
  .table-bordered.detail-view {
    th,td {
      display: block;
      width: 100%;
    }
    td {
      background: #fff;
    }
  }
}