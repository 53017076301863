
.header__wrap {
  padding: 0 15px 10px;
  border-bottom: 1px solid #ddd;
  position: relative;
  margin-bottom: 40px;
}
.header {
  @include cf();
}
.header__logo {
  float: left;
  margin-right: 15px;
}
.header__logo-image {
  display: block;
  margin-top: 15px;
}
.header__navigation {
  float: left;
  z-index: 2;
}
.header__navigation-btn {
  display: none;
}
.navigation__list {
  @include cf();
  padding: 0;
}
.navigation__item {
  float: left;
  margin: 0 15px;
  list-style-type: none;
  border-top: 5px solid transparent;
  &.active {
    border-top: 5px solid #44B770;
  }
}
.navigation__link {
  display: inline-block;
  padding: 20px 0 0;
  //letter-spacing: -1.3px;
  &:hover {
    color: inherit;
  }
}
.header__user-menu {
  position: relative;
  float: right;
}
.user-menu__header {
  display: block;
  padding: 10px 10px;
}
.user-menu__list {
  display: block;
  position: absolute;
  left: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s ease-in-out;
}
.user-menu__item {
  list-style-type: none;
}

.header__user-menu {

}
.header__user-menu {
  margin-right: 15px;
  .dropdown-toggle {
    font-size: 18px;
    padding: 18px 0 0;
    border-top: 5px solid transparent;
    &:hover {
      background: transparent;
      color: inherit;
      text-decoration: underline;
    }
  }
  .open {
    .dropdown-toggle {
      &:hover,&:focus { border-top: 5px solid #44B770;}
      border-top: 5px solid #44B770;
      color: #44B770;
    }
  }
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background: transparent;
}
.nav>li>a:focus, .nav>li>a:hover {
  text-decoration: none;
  background-color: transparent;
  color: inherit;
}
.dropdown {
  position: relative;
}
@media screen and (max-width: 1199px) {
  .header {
    width: 100%;
    margin: 0;
  }
  .navigation__item {
    margin: 0 10px;
  }
  .header__user-menu {
    margin-right: 0;
    .dropdown-toggle {
      display: block;
      width: 48px;
      height: 48px;
      border-top: 5px solid transparent!important;
      background: url("../images/user.svg") center/auto 130% no-repeat!important;
      font-size: 0;
      &:hover, &focus {
        border-top: 5px solid transparent!important;
        background: url("../images/user.svg") center/auto 130% no-repeat!important;
      }
      .caret {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 959px) {
  .header__wrap {
   padding: 15px 0;
  }
  .header {
    text-align: center;
  }
  .header__logo {
    display: inline-block;
    float: none;
    margin: 0;
  }
  .header__logo-image {
    margin-top: 5px;
  }
  .header__navigation-btn {
    display: block;
    width: 48px;
    height: 48px;
    float: left;
    background: url("../images/humburger.svg") center no-repeat;
  }
  .header__navigation {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background: #fff;
    border: solid #eee;
    border-width: 3px 0;
    transform-origin: top;
    transition: transform 0.5s ease-in-out;
    opacity: 0;
    transform: scaleY(0);
    &._open {
      transform: scaleY(1);
      opacity: 1;
    }
    &._close {
      transform: scaleY(0);
      transition: transform 0.5s ease-in-out, opacity ease 0.5s;
    }
  }
  .navigation__list {
    margin: 10px 0;
  }
  .navigation__item {
    float: none;
    border: none;
    &.active {
      border: none;
    }
  }
  .navigation__link {
    padding: 10px 0;
  }

  .header__user-menu {
    position: static;
    li {
      &._login {
        border-bottom: 3px solid #eee;
        padding-bottom: 3px;
        margin-bottom: 5px;
      }
      position: static;
    }
    .dropdown-menu {
      display: block;
      width: 100%;
      text-align: center;
      margin: 0;
      border: solid #eee;
      border-width: 3px 0;
      border-radius: 0;
      box-shadow: none;
      transform-origin: top;
      transition: transform 0.5s ease-in-out;
      &._open {
        transform: scaleY(1);
      }
      &._close {
        transform: scaleY(0);
      }
    }
  }
}