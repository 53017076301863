label {
  font-weight: 400;
}

.page__navigation {
  .btn {
    line-height: 40px;
  }
}

.btn {
  line-height: 22px;
  border-radius: 20px;
  background-image: none;
  box-shadow: none;
  margin-bottom: 25px;
  &.btn-primary {
    box-shadow: none;
    border: none;
    background: #44B770 !important;
    min-width: 180px;
  }
  &.btn-default {
    box-shadow: none;
    border: none;
  }
  &.btn-success {
    box-shadow: none;
    border: none;
  }
  &.btn-warning {
    box-shadow: none;
    border: none;
  }
}

.form-group {

  .control-label {
    font-weight: 400;
  }
  .form-control {
    border-radius: 20px;
    height: 40px;
    max-width: 640px;
    border: 1px solid #B6B6B6;
    outline: none;
  }
  .help-block {

  }
  &.has-success {
    .control-label {
      color: #3c763d;
    }
    input.form-control {
      &:focus {
        border-color: #3c763d;
      }
      background: #eef6ef;
    }
  }
  &.has-error {
    .control-label {
      color: #a94442;
    }
    input.form-control {
      &:focus {
        border-color: #a94442;
      }
      background: #ede3e0;
    }
  }
}

.panel {

  &.panel-default {

  }
  .panel-title {
    display: inline-block;
  }
  .panel-heading {
    display: block;
    img {
      vertical-align: sub;
    }
    label {
      margin: 0;
    }
  }
  &.field-projectform-geo {
    margin-bottom: 0;
  }
}

#projectform-searchengine {
  .btn {

    &.active {

    }
  }
}

.i-check input[type="checkbox"] + label span {
  float: left;
  border-radius: 33px;
  width: 41px;
  margin-top: -2px;
  height: 24px;
  border: 1px solid #DCDCDC;
  display: block;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;

}

.i-check input[type="checkbox"] + label span:before {
  background: url(../images/circle-check.png);
  width: 31px;
  height: 31px;
  left: -5px;
  top: -5px;
  position: absolute;
  content: '';
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.i-check input[type="checkbox"]:checked + label span:before {
  left: 14px;
}

.i-check input[type="checkbox"]:checked + label span {
  background: #63bd62;
}

.i-check {
  margin-bottom: 31px;
}

.i-check label {
  font-weight: normal;
}

.i-check input[type="checkbox"] {
  display: none;
}

.i-check label span {
  margin-right: 20px;
  line-height: 0;
}

.table {
  &.table-hover {
    thead {
      th {
        font-family: geometria;
        font-weight: 500;
      }
    }
    tbody {
      tr {
        &:hover {
          background: #fcfae3 !important;
        }
        td {

        }
      }
      .footable-detail-row {
        td {
          padding: 5px 0!important;
        }
        .footable-details {
          th {
            border-top: 0 !important;
            border-bottom: 0 !important;
            vertical-align: middle;
            background-color: transparent !important;
          }
          td {
            border-top: 0 !important;
            & > * {
              width: 100% !important;
            }

          }
        }
        .form-control {
          width: 100%;
        }
      }
    }
  }
  &.partners__table {
    .footable-detail-row {
      td {
        padding: 0 !important;
      }
    }
  }
  &.my-finance {
    thead {
      background-color: #F7F5F5!important;
      th {
        font-weight: 400;
      }
    }
    tfoot {
      td {
        padding: 16px 0;
      }
    }
  }
}

.dropdownMenu2 .btn-default, .dropdownMenu3 .btn-default {
  width: 100% !important;
  height: 40px !important;
  border-radius: 20px !important;
  border: 1px solid #B6B6B6 !important;
  color: #7D8C8F !important;
  text-align: left !important;
  background: url('/images/arrow-select.png') no-repeat #ffffff !important;
  background-position: right 15px top 15px !important;
}

.dropdownMenu2 .btn-default:hover, .dropdownMenu3 .btn-default:hover {
  color: #44B770;
}

.dropdown_title {
  padding-left: 10px !important;
}

.dropdownMenu2.open .btn-default, .dropdownMenu3.open .btn-default {
  border: #44B770 1px solid !important;
}

.dropdownMenu2 .dropdown-menu, .dropdownMenu3 .dropdown-menu {
  width: 92%;
  left: 4%;
  border-top: none;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0px !important;
  border: #44B770 1px solid !important;
  border-top: none !important;
  height: auto;
  max-height: 300px;
  overflow-x: hidden;
}


.dropdownMenu2 .dropdown-menu a:hover, .dropdownMenu3 .dropdown-menu a:hover {
  background: none !important;
  color: #44B770 !important;
}
.dropdown-menu>li>a {
  white-space: normal;
}
@media screen and (max-width: 767px) {
  .panel {
    .panel-heading {
      padding: 10px;
    }
  }
}
.footable-toggle {
  opacity: 1!important;
}
.footable-toggle.fooicon:before {
  //font-size: 20px;
  //color: #7d8c8f;
  //vertical-align: sub;
  content: '';
  display: block;
  width: 24px;
  height: 16px;
  background: url('../images/arrow-table.png') center/17px auto no-repeat;
}
.footable-toggle.glyphicon-menu-right:before {
  background: url('../images/arrow-table.png') center/17px auto no-repeat;
  transform: rotate(-90deg);
}