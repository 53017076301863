@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}
/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

html *{
  box-sizing: border-box;
}
html,body {
  overflow-x: hidden;
  line-height: 1.42857143;
  display: table;
  width: 100%;
  height: 100%;
}
.wrap_main {
  overflow-x: hidden;
  display: table-row;
  height: 100%;
}
//bootstrap
.container {
  width: 100%;
  max-width: 1215px;
  margin: 0 auto;
}
.glyphicon {
  &:hover {
    opacity: 0.8!important;
  }
}
a {
  &:hover {
    color: inherit;
  }
}
.popover {
  color: #7d8c8f;
}
.glyphicon-play {
    color: #44B770 !important;
    text-decoration: none !important;
}