.table-posts {
  thead {
    background: #F7F5F5;
  }
  tbody {
    tr {
      &:hover {
        background: #fcfae3 !important;
      }
    }
    .show-full {
      border-bottom: 1px dashed;
      &:hover {
        border-bottom: 1px dashed transparent;
      }
    }
  }
  .footable-detail-row {
   & > td {
      padding: 0;
      border: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .table-posts {
    th,td {
      &:first-child {
        width: 70px!important;
      }
    }
    .posts__link-type {
      text-decoration: none;
      &:hover {
        p:last-child {
          text-decoration: underline;
        }
      }
      p:last-child {
        word-break: break-word;
      }
    }
  }
}